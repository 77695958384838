import './home.scss'

import {
  ArrowRightIcon,
  BuildingLibraryIcon,
  ChartBarIcon,
  CheckIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  ReceiptPercentIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline'
import { ON_APPLICATION_UTM_DATA } from 'actions'
import {
  imgBgGray,
  imgBgMountain,
  imgBlackHome,
  imgCircleGraph,
  imgCompany1,
  imgCompany2,
  imgCompany3,
  imgCompany6,
  imgGrayHome,
  imgGreenGraph,
  imgHomeDownsideRiskProtection,
  imgLaptop,
} from 'assets'
import { CustomXsCarousel } from 'components'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { SignInModal } from 'pages/Auth/modal'
import { FaqFooter } from 'pages/Base'
import { Loan, LoanStatus } from 'pages/Marketplace'
import { remainingLoanTerm } from 'pages/Marketplace/InvestCard'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from 'reducers'
import { findLoans } from 'services/apis'
import { addTitleToLoans, getPrice3decimal, normalizeNumber, toS3Link, useTitle } from 'utils'

import { DashboardDemo } from './DashboardDemo'
import { dummyLoans } from './data'
import { MarketplaceDemo } from './MarketplaceDemo'
import { TakeControlWealthDemo } from './TakeControlWealthDemo'

export function HomePage() {
  useTitle('Invest in high-yield, premium real estate secured loans.')

  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const [activeProcess, setActiveProcess] = useState(0)
  const [activeLoan, setActiveLoan] = useState(0)
  const [activeNav, setActiveNav] = useState(0)
  const [loans, setLoans] = useState<Loan[]>([])
  const [isLoading, setLoanding] = useState(false)
  const [isSignInModal, showSignInModal] = useState(false)

  const navigate = useNavigate()

  const auth = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = auth

  useEffect(() => {
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    if (searchParams.get(utmKeys[0]) == null) {
      dispatch({
        type: ON_APPLICATION_UTM_DATA,
        utmData: null,
      })
      return
    }

    const utmData: Record<string, any> = {}
    utmKeys.forEach((key) => {
      utmData[key] = searchParams.get(key)
    })

    dispatch({
      type: ON_APPLICATION_UTM_DATA,
      utmData,
    })
  }, [])

  useEffect(() => {
    setLoanding(true)
    findLoans(
      {
        closed: false,
        orderBy: 'loanAmount',
        orderDir: -1,
        pageNum: 0,
        ltv: undefined,
        termLeft: undefined,
        status: [LoanStatus.ACTIVE],
        skip: 0,
        count: 4,
      },
      isAuthenticated,
    )
      .then(({ data }) => {
        const nData = addTitleToLoans(data)
        setLoans(nData)
      })
      .finally(() => setLoanding(false))
  }, [])

  const principalComponents = [
    <div className={`md:flex flex-1 items-center xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16`}>
      <div className="inline-block py-8 ml-auto mr-auto">
        <div className="relative mb-20">
          <Image src={imgGrayHome} className="max-w-[80%] xs:max-w-[100%] !block mx-auto" />
          <div className="absolute right-0 -bottom-16 xs:-bottom-10 rounded-md shadow-lg p-4 max-w-xs bg-white">
            <Image src={imgCircleGraph} className="absolute -top-14 -right-4 xs:-right-12 xs:w-32 w-24" />
            <p className="text-gray-700 text-sm mb-2">Loan Overview</p>
            <div className="grid grid-cols-2 text-sm font-semibold">
              <div>
                <p className="mb-2">Property Price</p>
                <p className="text-base">$390,000</p>
              </div>
              <div>
                <p className="mb-2">Deposit</p>
                <p className="text-base">
                  $39,000
                  <span className="bg-emerald-50 text-emerald-500 px-1 py-0.5 ml-1">10%</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="text-3xl font-semibold mb-4">Principal Protection™</p>
        <p className="text-md text-gray-700 font-medium md:max-w-md">
          Finresi reserves a small portion of generated interest from all performing loans into our Principal Protection
          account. This acts as a safety net to protect investors against loss of principal in the unlikely event of a
          foreclosure.
        </p>
      </div>
    </div>,

    <div
      className={`md:flex flex-1 items-center xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16 bg-black/90 border-2 rounded-2xl xs:border-none`}
    >
      <div className="inline-block py-8 ml-auto mr-auto">
        <div className="relative mb-14 mt-10">
          <Image src={imgBlackHome} />
          <div className="absolute right-0 -top-10 rounded-md shadow-lg p-4 max-w-xs bg-white">
            <div className="flex gap-2">
              <div>
                <div className="rounded-full bg-light-green/20 p-1">
                  <CheckIcon className="text-light-green w-4 h-4" />
                </div>
              </div>
              <div>
                <p className="text-lg font-semibold mb-1">Property is sold</p>
              </div>
            </div>
            <Image src={imgGreenGraph} className="-mt-2 h-24" />
          </div>
        </div>
        <p className="text-3xl mb-4 text-gray-100">Secondary Market Option</p>
        <p className="text-md text-gray-200 md:max-w-md mb-7">
          Exclusively for Finresi investors, our secondary market gives you the option to liquidate holdings before
          maturity.
          <span className="italic ml-2">Loan terms are generally 12 months, penalty fees apply.</span>
        </p>
      </div>
    </div>,

    <div
      className={`grid md:grid border-2 rounded-2xl md:grid-cols-2 grid-cols-1 items-center mx-0 mb-6 overflow-hidden bg-white`}
    >
      <div className="flex-1 flex items-center xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16 bg-white">
        <div className="inline-block md:py-16 py-8 ml-auto mr-auto">
          <p className="text-3xl font-semibold mb-4">High Standards for High Yields.</p>
          <p className="text-md text-gray-700 font-medium md:max-w-md">
            Every opportunity on the Finresi Platform is pre-vetted and purchased by us. We invest alongside you to
            ensure incentives remain aligned — we want to earn our interest and get repaid, too.
          </p>
        </div>
      </div>
      <div className="flex-1 flex items-center xs:px-6 px-4 md:px-6 bg-gray-50 -order-1 md:order-2 overflow-hidden">
        <div className="w-full ml-auto mr-auto relative xs:-mt-8 md:mt-0 xs:-mb-8 md:mb-0 font-medium">
          <div className="border-r border-r-gray-300 py-48 relative">
            <div className="border-x border-x-gray-300 absolute left-[13.5rem] right-[25%] top-0 bottom-0" />
            <div className="w-full bg-gradient-to-r from-indigo-500 to-orange-500 h-10 relative" />
          </div>

          <div className="absolute left-0 lg:top-20 md:top-4 xs:top-20 top-4">
            <div className="flex gap-3 items-center">
              <p className="w-48 bg-white rounded-md border border-gray-300 px-3 py-4 text-xs">
                We review more than $500M in loans, equaling 1000s of properties.
              </p>
              <div className="w-6 h-6 rounded-md bg-black" />
            </div>
          </div>
          <div className="absolute right-[calc(25%-0.75rem)] bottom-20">
            <div className="flex gap-3 items-center">
              <p className="w-48 bg-white rounded-md border border-gray-300 px-3 py-4 text-xs">
                We underwrite a select group of chosen loans to our stringent criteria.
              </p>
              <div className="w-6 h-6 rounded-md bg-black" />
            </div>
          </div>
          <div className="absolute -right-3 lg:top-20 md:top-[6.5rem] xs:top-20 top-[6.5rem]">
            <div className="flex gap-3 items-center">
              <p className="w-48 bg-white rounded-md border border-gray-300 px-3 py-4 text-xs">
                Only about 10% of loans are approved and purchased for investment.
              </p>
              <div className="w-6 h-6 rounded-md bg-black" />
            </div>
          </div>
        </div>
      </div>
    </div>,

    <div className={`flex flex-col-reverse md:block bg-white border-2 rounded-2xl overflow-hidden h-full`}>
      <div className="xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16">
        <div className="inline-block pt-10 md:pb-0 pb-10 ml-auto mr-auto">
          <p className="text-3xl font-semibold mb-4">Take Control of Your Wealth.</p>
          <p className="text-md text-gray-700 font-medium md:max-w-md">
            Pre-vetted loans, diversification across investors, properties, and regions, and our easy-to-use tools make
            it easy to make informed investing decisions.
          </p>
        </div>
      </div>
      <TakeControlWealthDemo />
    </div>,

    <div className={`flex flex-col-reverse md:block flex-1 bg-white border-2 rounded-2xl overflow-hidden h-full`}>
      <div className="xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16">
        <div className="inline-block pt-10 md:pb-0 pb-10 ml-auto mr-auto">
          <p className="text-3xl font-semibold mb-4">Downside Risk Protection</p>
          <p className="text-md text-gray-700 font-medium md:max-w-md">
            Every investment is secured by the underlying property as a first-lien position, meaning the loan is first
            priority to be paid back.
          </p>
        </div>
      </div>
      <div className="relative">
        <img src={imgHomeDownsideRiskProtection} className="object-none mx-auto h-80" />
        <div className="absolute w-48 border left-[calc(50%-6rem)] bottom-14 rounded-xl overflow-hidden">
          <p className="bg-light-green px-4 py-1.5 text-2xs text-white">Investment Value</p>
          <div className="bg-white px-4 pt-2 py-2">
            <p className="text-light-green text-sm font-medium">
              $<span className="text-lg">250,000</span>.00
            </p>
            <div>
              <span className="bg-yellow-200 text-2xs p-1 rounded-md">Low Risk</span>
            </div>
            <div className="text-right mb-1">
              <p className="text-xl font-bold -my-1">78%</p>
              <p className="text-2xs text-gray-400">Progress</p>
            </div>
            <div className="bg-green-200 w-full">
              <div style={{ width: '78%' }} className="rounded-sm h-1 bg-light-green" />
            </div>
          </div>
        </div>
      </div>
    </div>,
  ]

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="w-full bg-white relative overflow-hidden">
        <img src={imgBgGray} className="absolute w-full top-0 left-0 right-0 bottom-0 h-full opacity-40" />

        <div className="fullContent pt-6 relative">
          {/* <div className="flex justify-center">
            <div className="flex gap-3 rounded-full p-2.5 bg-white text-sm items-center">
              <span className="bg-yellow-300 text-gray-700 font-medium px-4 py-2 rounded-full">News</span>
              <p className="font-medium flex-1">Discover alternative investing of real estate backed loans</p>
              <ArrowRightIcon className="w-6 h-3 pr-3" />
            </div>
          </div> */}

          <div className="grid xl:flex xl:mt-14 mt-8 gap-10 xl:gap-16">
            <div className="flex-[3] xl:max-w-lg z-10">
              <p className="text-3xl md:text-5xl font-semibold mb-10">
                Empower your portfolio with real estate secured loans.
              </p>

              <p className="mb-10 text-lg">
                Finresi makes investing in real estate debt simple. Consistant monthly returns, No property management
                hassles required.
              </p>

              <div className="flex gap-3 xl:mb-8">
                <Link
                  to="/auth/signUp"
                  className="py-3 px-6 bg-light-green rounded-full text-black hover:bg-white hover:text-gray-600 font-semibold"
                >
                  Sign Up
                </Link>
                <Link
                  to="/how_it_works"
                  className="flex items-center gap-3 py-3 px-6 bg-indigo-600 rounded-full text-white hover:bg-white hover:text-indigo-500 font-semibold"
                >
                  How it Works
                  <ArrowRightIcon className="w-3 h-3" />
                </Link>
              </div>
            </div>
            <div className="flex-[2] overflow-visible mb-20 mt-6 xl:mt-0 sm:w-[90%] md:w-[75%] xl:w-[50%] mx-auto xl:pr-20">
              <div className="relative w-full">
                <div className="absolute aspect-[961/657] left-[-22%] top-[-5%] right-[-17.5%]">
                  <img src={imgLaptop} className="object-contain w-full h-full" />
                </div>
                <div className="w-full aspect-[686/437] overflow-hidden">
                  <DashboardDemo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-10 lg:mb-20">
        <div className="bg-white lg:bg-white/0 relative py-10">
          {/* <div className="hidden lg:block absolute bg-gradient-to-b from-[#233d7f]/0 to-[#233d7f] left-0 right-0 bottom-0 h-[200%]" /> */}
          {/* lg:bg-gradient-to-b from-[#233d7f]/0 to-[#233d7f] */}
          <div className="fullContent">
            <div className="grid gap-y-8 lg:grid-cols-6 md:grid-cols-3 grid-cols-2 relative text-black">
              {[
                ['$500M+', '', 'Loans Evaluated per Year'],
                ['50', '', 'States to Invest In'],
                ['100', '%', 'Principal Repaid'],
                ['10-12', '%', 'APY'],
                ['25', ' Years', 'Team Expertise'],
                ['$5,000', '', 'Investment Minimum'],
              ].map((v, index) => (
                <div className="flex items-center" key={v[2]}>
                  <div className="text-center flex-1">
                    <p className="mb-2">
                      <span className="text-3xl lg:text-3xl xl:text-4xl font-semibold lg:font-medium">{v[0]}</span>
                      <span className="text-3xl lg:text-2xl font-semibold">{v[1]}</span>
                    </p>
                    <p className="text-xs lg:text-black/80">{v[2]}</p>
                  </div>
                  {index != 5 && <div className="h-[80%] w-0.5 bg-gray-200 hidden lg:block" />}
                </div>
              ))}
              <div className="col-span-full lg:hidden flex items-center !border-l-white/0">
                <Link
                  to="/marketplace"
                  className="w-full xs:max-w-sm mx-auto block items-center gap-3 py-3 px-8 text-lg lg:text-base xl:text-base bg-light-green text-center rounded-full text-black/80 hover:bg-white hover:text-gray-600 font-semibold border border-white hover:border-light-green"
                >
                  Start Investing
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full relative overflow-hidden">
          <div className="text-center text-3xl md:text-4xl text-gray-500 font-normal mb-8">Featured Opportunities</div>
          <div className="flex justify-center relative fullContent">
            <CustomXsCarousel
              activeIndex={activeLoan}
              onSlideChange={(index: number) => setActiveLoan(index)}
              className="hidden xs:grid sm:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4"
            >
              {!!loans.length
                ? loans.map((item, index) => (
                    <div
                      className={`relative w-72 rounded-lg overflow-hidden hover:cursor-pointer mx-auto mb-12 mt-auto xs:my-auto`}
                      key={index}
                      onClick={() => {
                        if (isAuthenticated) navigate(`/marketplace/${item.id}`)
                        else showSignInModal(true)
                      }}
                    >
                      <Image src={toS3Link(item.images)} className="w-full block aspect-video object-cover" />
                      <div className="bg-white p-4 pb-2 border-l border-r border-gray-300">
                        <div className="flex gap-4 items-center mb-1">
                          <div className="flex-1">
                            {isAuthenticated && <p className="text-xs">{item.progress}% Funded</p>}
                            <div className="w-full bg-gray-200 rounded-full">
                              <div className="bg-light-green h-1 rounded-full" style={{ width: `${item.progress}%` }} />
                            </div>
                          </div>
                          <span className=" bg-gray-200 text-xs text-black font-semibold px-1.5 py-1 rounded-md capitalize">
                            $ {getPrice3decimal(item.loanAmount)}
                          </span>
                        </div>

                        <p className="font-bold mb-1 text-base">
                          {item.title} #{item.id}
                        </p>
                        <p className="text-xs text-gray-400 h-8">
                          {item.propertyType} with {item.bedrooms} bedrooms and {''}
                          {item.bathrooms} full bath
                        </p>
                      </div>

                      <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0 pb-2">
                        <div className="flex divide-x">
                          {[
                            [`${item.ytm}%`, 'APY'],
                            [`${normalizeNumber(item.ltv, 2)}%`, 'LTV'],
                            [remainingLoanTerm(item.maturityDate), 'TERM'],
                          ].map((v, idx) => (
                            <div key={`${index}-${idx}`} className="flex-1 text-center py-2">
                              <p className="font-bold text-xs">{v[0]}</p>
                              <p className="text-2xs text-gray-400">{v[1]}</p>
                            </div>
                          ))}
                        </div>
                        <div className="px-4 py-2">
                          <div className={`flex gap-4 items-center justify-center`}>
                            <div
                              // to={isAuthenticated ? `/marketplace/${item.id}` : '/auth/signIn'}
                              className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                            >
                              {/* {item.closed || item.funds == item.loanAmount ? 'View Details' : 'Invest Now'} */}
                              View Details
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : dummyLoans.map((item, index) => (
                    <div
                      key={`${item.title}-${index}`}
                      className={`w-72 rounded-xl overflow-hidden mx-auto mb-12 mt-auto xs:my-auto`}
                    >
                      <Image src={item.image} className="w-full block aspect-video object-cover" />
                      <div className="bg-white p-6 border-l border-r border-gray-300">
                        <p className="font-bold mb-2">{item.loan}</p>
                        <p className="text-xs text-gray-400">{item.title}</p>
                      </div>
                      <div className="bg-gray-100 flex divide-x border rounded-b-xl border-gray-300 border-t-0">
                        {[
                          [`${item.ytm}%`, 'APY'],
                          [`${normalizeNumber(item.ltv, 0)}%`, 'LTV'],
                          [item.term, 'TERM'],
                        ].map((v) => (
                          <div key={v[1]} className="flex-1 text-center py-6">
                            <p className="font-bold text-sm">{v[0]}</p>
                            <p className="text-xs text-gray-400">{v[1]}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
            </CustomXsCarousel>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-100 md:py-24 py-12 mb-20">
        <div className="content">
          <div className="flex justify-center">
            <p className="text-3xl md:text-5xl font-semibold mb-4 text-center">We’re in good company.</p>
          </div>
          <p className="font-semibold mb-4 text-center">Backed by:</p>
          <div className="flex flex-wrap gap-6 items-center justify-center">
            {[imgCompany1, imgCompany2, imgCompany3, imgCompany6].map((v) => (
              <img key={v} src={v} className="max-h-[2rem] lg:max-h-[4rem] lg:scale-90" />
            ))}
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="content mb-20">
          <div className="flex lg:flex-row flex-col gap-6 gap-y-10 items-center">
            <div className="flex-[2]">
              <p className="sm:text-5xl text-4xl font-semibold mb-6">Comparing your investment options.</p>
              <p className="text-gray-500 mb-6">
                With Finresi, you now have access to the type of investments — and returns — typically reserved for
                banks and institutions.
              </p>
              <div className="flex gap-3">
                <Link
                  to="/auth/signUp"
                  className="py-3 px-6 bg-light-green rounded-full text-black hover:bg-white hover:text-gray-600 border border-white hover:border-gray-300 font-semibold"
                >
                  Sign Up
                </Link>
                <Link
                  to="/how_it_works"
                  className="flex items-center gap-3 py-3 px-6 bg-white rounded-full text-black hover:bg-indigo-500 hover:text-white font-semibold border border-white hover:border-gray-300"
                >
                  How it Works
                  <ArrowRightIcon className="w-3 h-3" />
                </Link>
              </div>
            </div>
            <div className="flex-[3] w-full">
              {[
                ['Municipal Bonds', '2.5%', 45],
                ['One-year CD', '4%', 50],
                ['AAA Corporate Bond', '4.5%', 55],
                ['One-year treasury', '5.25%', 65],
                ['Commercial Real Estate', '8.5%', 75],
                ['Finresi - Private Real Estate Debt', '10.5%', 100, '!bg-indigo-500 text-white'],
              ].map((v) => (
                <div
                  key={v[0]}
                  style={{ width: `${v[2]}%` }}
                  className={`bg-black/10 mb-4 px-2 md:px-4 py-2 rounded-md text-sm sm:text-base md:text-lg flex justify-between border ${v[3]} ring-2 ring-gray-300`}
                >
                  <p>{v[0]}</p>
                  <p>{v[1]}</p>
                </div>
              ))}
              <p className="text-sm text-gray-600">2023 YTD Benchmark Returns</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-100 md:py-24 pt-16 pb-10">
        <div className="content">
          <div className="flex flex-col justify-between sm:mb-12 mb-8">
            <p className="sm:text-5xl text-4xl mb-4 font-semibold sm:text-center">Finresi Process & Protections</p>
            <p className="text-base text-gray-500 max-w-3xl mx-auto sm:text-center">
              We give accredited investors access to real estate investments historically reserved just for banks and
              institutions — allowing you to invest and earn the way they do.
            </p>
          </div>

          <div className="flex flex-wrap justify-between xs:bg-white/40 gap-4 rounded-full p-2 mb-3">
            {[1, 2, 3, 4].map((v, index) => {
              const isActive = activeProcess == index
              return (
                <div key={`process-${v}`} className="xs:flex-1">
                  <p
                    className={`inline-block ${
                      isActive ? 'bg-indigo-500 text-white' : 'bg-white text-indigo-500'
                    } md:bg-white md:text-indigo-500 rounded-full w-16 h-16 py-5 text-center text-base font-bold hover:bg-indigo-100 hover:text-indigo-500 cursor-pointer`}
                    onClick={() => {
                      setActiveProcess(index)
                    }}
                  >
                    {v}
                  </p>
                </div>
              )
            })}
          </div>

          <CustomXsCarousel
            activeIndex={activeProcess}
            onSlideChange={(index: number) => setActiveProcess(index)}
            className="hidden xs:grid lg:grid-cols-4 grid-cols-2 gap-2 md:gap-4"
          >
            {[
              [
                ClockIcon,
                'Evaluate & Purchase Loans',
                'Through our exclusive lender partners, we diligence hundreds of real estate loans funded to experienced investors.',
              ],
              [
                BuildingLibraryIcon,
                'Invest in Good Debt',
                'Once purchased the loan is offered on our platform for accredited investors to invest and earn up to 12% APY.',
              ],
              [
                DocumentIcon,
                'Earn Monthly Income',
                'Start accruing interest from the day your money clears your dashboard, and get income payments every month for the term of the loan.',
              ],
              [
                ReceiptPercentIcon,
                'Keep Your Money Working',
                'Once the loan is repaid, you’ll receive your principal to reinvest in more real estate opportunities.',
              ],
            ].map((v) => {
              const Icon = v[0]
              return (
                <div
                  className={`relative md:block flex-1 py-8 xs:py-6 px-12 xs:px-6 bg-white rounded-xl`}
                  key={v[1] as string}
                >
                  <Icon className="w-10 h-10 mb-2 text-indigo-500" />
                  <p className="font-semibold mb-1 text-base">{v[1] as string}</p>
                  <p className="text-sm">{v[2] as string}</p>
                </div>
              )
            })}
          </CustomXsCarousel>
        </div>
      </div>

      <div className="w-full bg-gray-100">
        <div className="content lg:pb-24 sm:pb-16 pb-8">
          <div className="flex md:hidden flex-wrap gap-4 mb-4">
            {['Protection™', 'Market', 'Yield', 'Control', 'Risk'].map((v, index) => {
              const isActive = activeNav == index
              return (
                <p
                  key={v}
                  className={`font-medium border-b-2 ${
                    isActive ? 'text-indigo-500 border-b-indigo-500' : 'border-b-white/0'
                  } cursor-pointer`}
                  onClick={() => setActiveNav(index)}
                >
                  {v}
                </p>
              )
            })}
          </div>

          <div className="md:hidden">{principalComponents[activeNav]}</div>

          <div className="hidden md:block">
            <div
              className={`md:grid border-2 rounded-2xl md:grid-cols-2 grid-cols-1 items-start mx-0 mb-6 overflow-hidden bg-white`}
            >
              {principalComponents[0]}
              {principalComponents[1]}
            </div>

            {principalComponents[2]}

            <div className={`md:grid md:grid-cols-2 grid-cols-1 items-center mx-0 mb-6 overflow-hidden gap-6`}>
              {principalComponents[3]}
              {principalComponents[4]}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative lg:pt-24 md:pt-14 bg-black/90 overflow-hidden">
        <img src={imgBgMountain} className="absolute left-0 top-0 right-0 bottom-0 w-full h-full" />

        <div className="content relative overflow-x-visible overflow-y-clip">
          <div className="flex flex-col-reverse md:flex-row">
            <div className="md:flex-1 flex-auto -mx-4 md:mx-0 overflow-visible h-144 md:h-0">
              <MarketplaceDemo />
              <div className="absolute bg-gradient-to-b from-black/0 to-[#1e2225] md:top-[30%] top-[70%] bottom-0 -left-[100vw] -right-[100vw]" />
            </div>
            <div className="flex-1 pt-6 md:pb-28 pb-12 relative">
              <div className="bg-white rounded-xl p-10 md:mb-20 mb-10">
                <p className="text-3xl font-bold mb-4">ROI at a Glance</p>
                <p className="text-base mb-8">
                  Track investments in real time and manage your portfolio on your personalized investor dashboard.
                </p>

                <div>
                  <Link
                    to="/how_it_works"
                    className="gap-3 py-3 px-6 bg-indigo-500 rounded-full text-white hover:bg-white hover:text-gray-600 font-semibold border border-indigo-500"
                  >
                    How it Works
                  </Link>
                </div>
              </div>
              <div className="max-w-lg text-white">
                <p className="text-3xl md:mb-20 mb-10">
                  “Finresi’s high-yield real estate loan investments provide me true passive income with complete
                  control and none of the real estate ownership headaches. It’s seamless and straightforward.”
                </p>
                <p className="text-base mb-2">Adam Smith</p>
                <p className="text-sm">Finresi Investor</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative lg:pt-24 md:pt-14 pt-10 pb-8 bg-[#22262A] overflow-hidden">
        <div className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-[url('./assets/home/bgGray.png')]" />

        <div className="content relative">
          <div className="flex flex-col md:flex-row justify-center gap-10 mb-40">
            <p className="text-white sm:text-4xl text-3xl md:mt-20 mx-auto md:mr-0 md:max-w-md w-full sm:w-112">
              Sound Good?
              <br />
              It’s easy to get started.
            </p>
            <div className="border rounded-xl p-10 border-white/50 mx-auto md:ml-0 md:max-w-md w-full sm:w-112">
              {[
                [UserPlusIcon, 'Create and register account'],
                [CreditCardIcon, 'Browse loan opportunities'],
                [MagnifyingGlassIcon, 'Select your investment'],
                [ChartBarIcon, 'Monitor and track earnings'],
              ].map((data, index) => {
                const Icon = data[0]
                return (
                  <div className="flex items-start gap-6 text-gray-300 sm:text-base text-sm mb-8" key={index}>
                    <div className="rounded-full mx-auto mb-2">
                      <Icon className="w-8 h-8" />
                    </div>
                    <div className="flex-1">
                      <p className="mb-4">
                        <span className="bg-white/10 px-3 py-2 rounded-md">Step {index + 1}</span>
                      </p>
                      <p className="px-2">{data[1] as string}</p>
                    </div>
                  </div>
                )
              })}
              <Link
                to="/auth/signUp"
                className="block py-3 text-center bg-indigo-500 rounded-full text-white hover:bg-white hover:text-gray-600 font-semibold"
              >
                Sign up
              </Link>
            </div>
          </div>
          <div className="max-w-full mx-auto">
            <p className="text-white text-4xl md:mb-12 mb-6 text-center capitalize">How it works</p>
            <div className="w-full flex justify-center mb-20">
              <video width="w-full" controls poster={`${process.env.PUBLIC_URL}/video_preview.png`}>
                <source src={`${process.env.PUBLIC_URL}/videos/home.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <FaqFooter />
        </div>
      </div>

      {isSignInModal && <SignInModal onClose={() => showSignInModal(false)} />}
    </div>
  )
}
