import {
  API_NOTIFICATION,
  API_USER_BY_ID,
  API_USER_CERT_FORMATION,
  API_USER_CERT_FORMATION_BY_ID,
  API_USER_INVEST_READY_BY_ID,
  API_USER_INVEST_READY_EXPIRES_ON_BY_ID,
  API_USER_SIGN_UP,
  API_USER_TAX_CERT_BY_ID,
  API_USER_VALIDATE_EMAIL,
  API_USERS,
} from 'config'
import Api from 'services/api'

export const createAccount = (data: Record<string, any>) => {
  return Api.post(API_USER_SIGN_UP, data)
}

export const validateEmailDuplication = (email: string) => {
  return Api.get(API_USER_VALIDATE_EMAIL, { email })
}

export const findUsers = (filters: Record<string, any>) => {
  return Api.get(API_USERS, filters)
}

export const updateUserData = (id: number, data: Record<string, any>) => {
  return Api.put(API_USER_BY_ID, data, { id })
}

export const createNewAccount = (data: Record<string, any>) => {
  return Api.post(API_USERS, data)
}

export const getNotifications = (filters: Record<string, any>) => {
  return Api.get(API_NOTIFICATION, filters)
}

export const getBadgeCount = () => {
  return Api.get(API_USER_BY_ID, {}, { id: 'badges' }, {}, false)
}

export const markAsAllNotificationRead = () => {
  return Api.post(API_USER_BY_ID, {}, { id: 'readNotification' })
}

export const sendConfirmEmail = (email: string) => {
  return Api.post(API_USER_BY_ID, { email }, { id: 'sendConfirmEmail' })
}

export const getUser = (id: number) => {
  return Api.get(API_USER_BY_ID, {}, { id })
}

export const getUserInvestReady = (userId: number) => {
  return Api.get(API_USER_INVEST_READY_BY_ID, {}, { id: userId })
}

export const getUserInvestReadyExpiresOn = (userId: number): Promise<{ expiresOn: string }> => {
  return Api.get(API_USER_INVEST_READY_EXPIRES_ON_BY_ID, {}, { id: userId })
}

export const rejectInvestReady = (id: number, reason: string) => {
  return Api.delete(API_USER_INVEST_READY_BY_ID, { reason }, { id })
}

export const submitUserInvestReady = (id: number) => {
  return Api.post(API_USER_INVEST_READY_BY_ID, {}, { id })
}

export const approveUserInvestReady = (id: number, expiresOn: string) => {
  return Api.put(API_USER_INVEST_READY_BY_ID, { expiresOn }, { id })
}

export const getUserTaxCert = (userId: number) => {
  return Api.get(API_USER_TAX_CERT_BY_ID, {}, { id: userId })
}

export const getUserTaxCertEnvelopeUrl = (userId: number, envelopeId: string) => {
  return Api.post(API_USER_TAX_CERT_BY_ID, { envelopeId }, { id: userId })
}

export const updateUserTaxCert = (userId: number, data: Record<string, any>) => {
  return Api.put(API_USER_TAX_CERT_BY_ID, data, { id: userId })
}

export const uploadCertFormation = (data: Record<string, any>, fileList: File[]) => {
  return Api.uploadFiles(API_USER_CERT_FORMATION, data, {}, fileList)
}

export const getUserCertFormation = (userId: number) => {
  return Api.get(API_USER_CERT_FORMATION_BY_ID, {}, { id: userId })
}

export const getUserCertFormationUrl = (userId: number, certFormationId: string) => {
  return Api.post(API_USER_CERT_FORMATION_BY_ID, { certFormationId }, { id: userId })
}

export const updateUserCertFormation = (userId: number, data: Record<string, any>) => {
  return Api.put(API_USER_CERT_FORMATION_BY_ID, data, { id: userId })
}
