import {
  imgAlumni,
  imgBallard,
  imgBen,
  imgBloomberg,
  imgBusinessInsider,
  imgChris,
  imgE,
  imgEraci,
  imgForbest,
  imgJeff,
  imgKirk,
  imgLesEchos,
  imgPhila,
  imgReallist,
  imgRtt,
  imgSomer,
  imgTc,
} from 'assets'
import { HorzScrollContainer } from 'components/HorzScrollContainer'
import { Image } from 'components/Image'
import type { IArticle } from 'pages/Article/types'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAllArticles, getLastArticles } from 'services/apis/articles'
import { useTitle } from 'utils'

import { FaqFooter } from './faqFooter'
export const AboutUsPage = () => {
  useTitle('About us')
  const data = [
    { month: 'Aug', value: 100, color: 'bg-blue-500' },
    { month: 'Sep', value: 30, color: 'bg-gray-200' },
    { month: 'Oct', value: 50, color: 'bg-gray-200' },
    { month: 'Nov', value: 15, color: 'bg-gray-200' },
    { month: 'Dec', value: 60, color: 'bg-blue-500' },
  ]

  const navigate = useNavigate()

  const [articles, setArticles] = useState<IArticle[]>([])
  const [lastArticles, setLastArticles] = useState<IArticle[]>([])
  useEffect(() => {
    getAllArticles().then((data) => {
      setArticles(data)
    })
    getLastArticles().then((data) => {
      setLastArticles(data)
    })
  }, [])

  const selectArticle = (article: IArticle) => {
    if (article.articleType === 'Article') {
      window.open(article.link, '_blank', 'noopener')
    } else {
      navigate('/blog/' + article.articleSlug)
    }
  }

  const convertToValidUrl = (input: string) => {
    try {
      // Attempt to create a URL object which will throw an error if the URL is invalid
      const url = new URL(input)
      return url.hostname // Returns the normalized URL
    } catch (error) {
      // If URL is invalid, handle accordingly (e.g., sanitize or default behavior)
      return ' ' // or handle default URL
    }
  }

  return (
    <div>
      <div className="w-full bg-white">
        <div className="flex flex-col content">
          <div className="text-center my-8 md:my-16 ">
            <p className="md:text-5xl text-4xl font-semibold p-4 inline-block rounded-lg mb-4 max-w-4xl  text-gray-800">
              Finresi unlocks the world of real estate loans for investors portfolios.
            </p>
            <div>
              <Link
                className="py-3 px-6 bg-light-green rounded-full text-black hover:bg-white hover:text-gray-600 font-semibold"
                to="/auth/signUp"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div className="md:flex md:justify-center">
            <div className="grid grid-cols-12 max-w-3xl gap-3 bg-[url('./assets/aboutus/world-map.svg')] bg-no-repeat pt-7 md:pb-28 md:px-12 md:ml-32">
              <div className="col-span-6 rounded-lg w-full p-3 shadow-md bg-white sm:col-span-4">
                <p className="text-light-green text-xs font-semibold mb-1">$150,000</p>
                <p className="text-gray-800 font-normal text-3xs">Total Profit</p>
              </div>
              <div className="col-span-6 rounded-lg w-full p-3 shadow-md bg-white sm:col-span-4">
                <p className="text-gray-800 text-xs font-semibold mb-1">$150,000</p>
                <p className="text-gray-800 font-normal text-3xs">Invested Cash</p>
              </div>
              <div className="col-span-6 rounded-lg w-full p-3 shadow-md bg-white sm:col-span-4">
                <p className="text-gray-800 text-xs font-semibold mb-1">$150,000</p>
                <p className="text-gray-800 font-normal text-3xs">Lifetime Deposit</p>
              </div>
              <div className="col-span-6 rounded-lg w-full p-3 shadow-md bg-white sm:col-span-4">
                <p className="text-light-green text-xs font-semibold mb-1">$45,000</p>
                <p className="text-gray-800 font-normal text-3xs">Withdrawn</p>
              </div>
              <div className="col-span-6 rounded-lg w-full p-3 shadow-md bg-white sm:col-span-4">
                <p className="text-gray-800 text-xs font-semibold mb-1">$45,000</p>
                <p className="text-gray-800 font-normal text-3xs">Upcoming Deposit</p>
              </div>
              <div className="col-span-6 rounded-lg w-full p-3 shadow-md bg-white sm:col-span-4">
                <p className="text-gray-800 text-xs font-semibold mb-1">$45,000</p>
                <p className="text-gray-800 font-normal text-3xs">Pending withdrawal</p>
              </div>
              <div className="col-span-12 rounded-lg shadow-lg px-3 py-4 h-fit w-fit md:-ml-24 md:mt-6 bg-white sm:col-span-6 md:col-span-4">
                <p className="font-semibold text-light-green text-lg">
                  $1,834,000 <span className="font-semibold text-black text-lg">Funded</span>
                </p>
                <div className="mt-2 bg-gray-200 rounded-full h-1 w-64">
                  <div className="bg-light-green h-1 rounded-full" style={{ width: `${75}%` }}></div>
                </div>
                <div className="mt-2 text-gray-500 text-2xs">Funded by 138 Investors</div>
              </div>
              <div className="col-span-12 rounded-lg w-full p-3 shadow-md bg-white h-fit sm:col-span-6 md:col-span-4">
                <p className="text-gray-800 text-xs font-semibold mb-1">$45,000</p>
                <p className="text-gray-800 font-normal text-3xs">
                  Next Scheduled Distribution amount Your earnings history will appear on Payment page.
                </p>
              </div>
            </div>
            <div className="shadow-lg rounded-lg px-4 py-2 h-fit max-w-xs md:-ml-9 md:-mt-6">
              <div className="flex justify-between items-center mb-2">
                <p className="text-3xs text-gray-800">Active Properties</p>
                <div className="flex">
                  <button className="rounded-sm border border-gray-200 text-3xs p-1 mr-1">2022</button>
                  <button className="bg-black rounded-sm text-white text-3xs p-1">2023</button>
                </div>
              </div>
              <div className="flex items-end space-x-3">
                {data.map((item, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className={`w-5 ${item.color} rounded-t`} style={{ height: `${item.value / 2}px` }}></div>
                    <span className="mt-1 text-gray-600 text-3xs">{item.month}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="text-center mt-8">
            <p className="uppercase font-normal text-gray-600 text-sm mb-12 tracking-widest">
              backed by institutional investors
            </p>
            <div className="flex flex-col gap-6 justify-center md:flex-row">
              <Image src={imgSomer} className="h-8 md:h-fit" />
              <Image src={imgAlumni} className="h-8 md:h-fit" />
              <Image src={imgE} className="h-8 md:h-fit" />
              <Image src={imgBen} className="h-8 md:h-fit" />
            </div>
          </div>
          <div className="text-center mt-16">
            <p className="uppercase font-normal text-gray-600 text-sm mb-12 tracking-widest">service partners</p>
            <div className="flex gap-6 justify-center">
              <Image src={imgBallard} className="h-8 md:h-fit" />
              <Image src={imgEraci} className="h-8 md:h-fit" />
            </div>
          </div>
          <div className="text-center mt-16 mb-32">
            <p className="uppercase font-normal text-gray-600 text-sm mb-12 tracking-widest"> as seen in </p>
            <div className="flex flex-col gap-6 justify-center md:flex-row">
              <Image src={imgRtt} className="object-contain h-8 md:h-fit" />
              <Image src={imgPhila} className="object-contain h-8 md:h-fit" />
              <Image src={imgReallist} className="object-contain h-8 md:h-fit" />
            </div>
          </div>
        </div>
        <div className="w-full bg-zinc-100 pt-24 pb-48">
          <div className="flex flex-col content">
            <p className="text-black font-semibold text-5xl mb-4">Our Blogs/News</p>
            <p className="text-black font-normal text-sm mb-3">Check out Finresi content available for all users</p>
            <HorzScrollContainer>
              <div className="pt-12 pb-2">
                <div className="flex gap-5">
                  {articles.map((article, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
                      style={{ minWidth: 320, width: 320 }}
                      onClick={() => selectArticle(article)}
                    >
                      <img
                        src={article.imageURL}
                        className="w-full h-48 object-cover"
                        alt={article?.altImage && article?.altImage.length > 0 ? article?.altImage : article?.title}
                      />
                      <div className="p-4">
                        <div className="flex space-x-2 mb-2">
                          {article.tags.split(',').map((tag, index1) => (
                            <span
                              key={index1}
                              className="bg-green-100 text-light-green text-xs font-semibold mr-1 px-1 py-0.5 rounded"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                        <h2 className="text-gray-900 font-medium text-sm">{article.title}</h2>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </HorzScrollContainer>

            <div className="mt-32">
              <div className="w-fit mx-auto">
                <p className="font-semibold text-black text-2xl mb-4">Our Mission</p>
                <p className="font-medium text-black text-4xl mb-4 uppercase">How Finresi came about</p>
                <p className="font-normal text-gray-700 text-sm md:w-160 leading-6">
                  At Finresi, our mission is to revolutionize the investment landscape by providing a secure,
                  transparent, and accessible online platform for debt investments. We strive to empower our investors
                  with innovative financial solutions, backed by stringent underwriting criteria and high-quality real
                  estate offerings. Our commitment to excellence and integrity ensures that every investment opportunity
                  is meticulously curated to deliver consistent returns and foster financial growth. Join us in
                  reshaping the future of alternative real estate investing!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col content pt-36 md:px-24">
          <p className="font-semibold text-4xl text-center mb-16">Executive team</p>
          <div className="grid grid-cols-12 gap-2 md:gap-12">
            <div className="col-span-12 md:col-span-4 mx-auto">
              <Image src={imgJeff} className="w-52 h-52 mx-auto md:w-80 md:h-80 object-contain" />
              <p className="font-medium text-2xl text-center mt-2 md:mt-12">Jeff Gopshtein</p>
              <p className="font-normal text-sm text-gray-700 text-center my-2">Co Founder and CEO</p>
            </div>
            <div className="col-span-12 md:col-span-4 mx-auto">
              <Image src={imgKirk} className="w-52 h-52 mx-auto md:w-80 md:h-80 object-contain" />
              <p className="font-medium text-2xl text-center mt-2 md:mt-12">Kirk Ayzenberg</p>
              <p className="font-normal text-sm text-gray-700 text-center my-2">Co Founder</p>
            </div>
            <div className="col-span-12 md:col-span-4 mx-auto">
              <Image src={imgChris} className="w-52 h-52 mx-auto md:w-80 md:h-80 object-contain" />
              <p className="font-medium text-2xl text-center mt-2 md:mt-12">Christian Leon</p>
              <p className="font-normal text-sm text-gray-700 text-center my-2">Head of Investor Relations</p>
            </div>
          </div>
          <div className="rounded-lg bg-[#5B79D1] pt-16 px-12 mt-40 pb-32 mb-32">
            <p className="font-bold text-white text-5xl">In the news</p>
            <div className="grid grid-cols-12 md:gap-12 mt-12 gap-4">
              {lastArticles.map((article, index) => (
                <div className="col-span-12 md:col-span-4" key={index}>
                  {article.link.includes('technical.ly') ? (
                    <Image src={imgTc} />
                  ) : article.link.includes('bloomberg') ? (
                    <Image src={imgBloomberg} />
                  ) : article.link.includes('forbes') ? (
                    <Image src={imgForbest} />
                  ) : article.link.includes('business') ? (
                    <Image src={imgBusinessInsider} />
                  ) : article.link.includes('lesechos') ? (
                    <Image src={imgLesEchos} />
                  ) : (
                    <p className="font-bold text-2xl text-white">{convertToValidUrl(article.link)}</p>
                  )}
                  <p className="font-semibold text-md text-white mt-4">{article.title}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full relative lg:pt-24 md:pt-14 pt-10 pb-8 bg-[#22262A] md:mt-88 mt-64">
          <div className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-[url('./assets/home/bgGray.png')]" />

          <div className="content relative">
            <div className="bg-[#15181A] relative md:p-16 px-6 py-10 rounded-xl overflow-hidden md:-mt-88 -mt-64 md:mb-20 mb-14">
              <div className="h-128 w-128 absolute -left-64 -top-64 bg-gradient-to-br from-indigo-500 to-indigo-500/0 blur-2xl rounded-full" />
              <div className="h-128 w-128 absolute -right-64 -bottom-64 bg-gradient-to-br from-indigo-500/50 to-indigo-500/0 blur-3xl rounded-full" />
              <p className="md:text-5xl xs:text-4xl text-3xl text-center relative text-white font-semibold max-w-4xl mb-8 mx-auto">
                Explore wealth creation through a resilient asset class. Sign up.
              </p>
              <div className="flex justify-center md:mb-10 mb-2">
                <Link
                  to="/auth/signUp"
                  className="py-3 px-8 bg-indigo-500 rounded-full text-white hover:bg-black hover:text-white font-semibold border border-white/0 hover:border-indigo-500"
                >
                  Sign Up
                </Link>
              </div>
            </div>

            <FaqFooter />
          </div>
        </div>
      </div>
    </div>
  )
}
