import {
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  EnvelopeOpenIcon,
  GlobeAltIcon,
  KeyIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import {
  AccountFilterStatusText,
  AccountStatus,
  AccountStatusText,
  AccountType,
  AccountTypeText,
  CompanyDetails,
  isTopManager,
  itemCountPerPage,
} from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { MannualTransaction } from 'pages/Payment'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import {
  blockUserBalance,
  createNewAccount,
  findUsers,
  getUserBalances,
  sendConfirmEmail,
  updateUserData,
} from 'services/apis'
import { Button, Input2, MultiSelect, Pagination, Select, Select2, Toggle } from 'stories/components'
import { confirm, flagLink, formatDateMDY, normalizeNumber, renderHeader, useTitle } from 'utils'

import { CertFormationDetails } from './CertFormationDetails'
import { CompanyDetail } from './CompanyDetails'
import { InvestReadyDetails } from './InvestReadyDetails'
import { CustomPassword } from './modal/CustomPassword'
import { TaxCertDetails } from './TaxCertDetails'
import type { User } from './type'
import { UserDetails } from './UserDetails'
import { UserKycDetails } from './UserKycDetails'

interface IBalances {
  available: number
  createdAt: Date
  updatedAt: Date
  deposited: number
  email: string
  holding: number
  id: number
  invested: number
  name: string
  pendingDistribution: number
  profits: number
  retradedFee: number
  upcoming: number
  userId: number
  withdrawn: number
  block: boolean
}

export const ManageUsersPage = () => {
  useTitle('User Management')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const defaultQuery = query.replace(/_/g, '#')
  const defaultInvestReadyId = Number(searchParams.get('investready') || 0)
  const defaultTaxCertId = Number(searchParams.get('taxCert') || 0)
  const defaultCertFormatId = Number(searchParams.get('certFormation') || 0)

  const defaultUserStatus: Record<string, boolean> = {}
  Object.keys(AccountFilterStatusText).forEach((key) => {
    defaultUserStatus[key] = false
  })

  const [filters, setFilters] = useState<Record<string, any>>({
    query: defaultQuery,
    userStatus: defaultUserStatus,
    orderBy: 'updatedAt',
    orderDir: '-1',
    pageNum: 0,
    deleted: false,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [users, setUsers] = useState<User[]>([])
  const [balances, setBalances] = useState<IBalances[]>([])
  const [balanceTotal, setBalanceTotal] = useState<Record<string, number> | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null | undefined>()
  const [kycSelectedUser, setKycSelectedUser] = useState<User>()
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [editPasswordId, setEditPasswordId] = useState<number>(-1)
  const [editPasswordName, setEditPasswordName] = useState('')
  const [activeCompany, setActiveCompany] = useState(0)
  const [activeInvestReady, setActiveInvestReady] = useState(
    defaultInvestReadyId ? Number(defaultQuery.replace('#', '')) : 0,
  )
  const [activeTaxCert, setActiveTaxCert] = useState(defaultTaxCertId ? Number(defaultQuery.replace('#', '')) : 0)
  const [activeCertFormat, setActiveCertFormat] = useState(
    defaultCertFormatId ? Number(defaultQuery.replace('#', '')) : 0,
  )
  const [showBalance, setShowBalance] = useState(false)

  const [currentMannualTrxUser, setMannualTrxUser] = useState<IBalances | null>(null)

  const auth = useSelector((state: RootState) => state.auth)
  const { accountType } = auth.profile
  const _isManager = isTopManager(accountType)

  useEffect(() => {
    if (showBalance) filterBalanceData(filters).then(() => setIsGetUsersOnce(true))
    else
      filterUserData(filters).then(() => {
        setIsGetUsersOnce(true)
      })
  }, [])

  useEffect(() => {
    if (showBalance) filterBalanceData(filters)
    else filterUserData(filters)
  }, [showBalance])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterBalanceData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (filters?.query) filters.query = filters.query.trim()

    setLoading(true)
    return getUserBalances({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total, totalValues }) => {
        setTotal(total)
        setBalances(data)
        setBalanceTotal(totalValues)
      })
      .finally(() => setLoading(false))
  }

  const filterUserData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    setLoading(true)
    const userStatus: number[] = []
    Object.keys(AccountFilterStatusText).forEach((key) => {
      if (filters.userStatus[key] !== false)
        userStatus.push(AccountFilterStatusText[key as keyof typeof AccountFilterStatusText])
    })
    if (!filters.accountType) delete filters.accountType
    if (filters?.query) filters.query = filters.query.trim()

    return findUsers({
      ...filters,
      userStatus: userStatus,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTotal(total)
        setUsers(data)
      })
      .finally(() => setLoading(false))
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (
    key: 'query' | 'accountType' | 'userStatus' | 'deleted' | 'orderBy' | 'orderDir' | 'pageNum',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) {
      if (showBalance) filterBalanceData(newFilters)
      else filterUserData(newFilters)
    }
  }

  const onChangeUser = async (id: number, index: number, key: string, value: any) => {
    const newUsers = cloneDeep(users)
    ;(newUsers[index] as any)[key] = value
    setUsers(newUsers)

    setLoading(true)
    await updateUserData(id, { [key]: value })
    setLoading(false)
  }

  const onSelect = (data: User | IBalances) => {
    setSelectedUser(data as User)
  }

  const onSelectKyc = (data: User) => {
    setKycSelectedUser(data)
  }

  const onResetPwd = async (index: number, name: string) => {
    setEditPasswordName(name)
    setEditPasswordId(index)
  }

  const onDeleteUser = async (index: number, userName: string) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this user?
        <br />
        <span className="text-gray-600">User Name: {userName}</span>
      </div>
    )

    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    updateUserData(users[index].id, { deleted: true })
      .then(() => {
        toast('The user has been deleted.', { type: 'success' })
        filterUserData(filters)
      })
      .catch(() => setLoading(false))
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string, className: string = '') => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      className,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        if (showBalance) filterBalanceData(newFilters)
        else filterUserData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  const onAdd = () => {
    setSelectedUser(null)
  }

  const onSubmitUser = async (data: Record<string, any>) => {
    try {
      setLoading(true)
      if (!selectedUser) {
        await createNewAccount({
          ...data,
          password: 'newpassword',
          isActive: true,
        })
        toast('New account has been created.', { type: 'info' })
      } else {
        if (showBalance) await updateUserData((selectedUser as any).userId, data)
        else await updateUserData(selectedUser.id, data)
        toast('The account has been updated.', { type: 'success' })
      }
      setSelectedUser(undefined)

      if (showBalance) filterBalanceData(filters)
      else filterUserData(filters)
      return true
    } catch (e) {
      setLoading(false)
      return false
    }
  }

  const onSubmitPassword = async (password: string) => {
    setLoading(true)
    updateUserData(users[editPasswordId].id, { password: password })
      .then(() =>
        password.length > 0
          ? toast('Password has been reset.', { type: 'info' })
          : toast('Password Reset Email has been sent.', { type: 'success' }),
      )
      .finally(() => {
        setLoading(false)
        setEditPasswordId(-1)
      })
  }

  const onSendConfirmEmail = (data: User) => {
    setLoading(true)
    sendConfirmEmail(data.email)
      .then(() => toast('A confirmation email has been sent.', { type: 'info' }))
      .finally(() => setLoading(false))
  }

  const onUpdateCompanyDetails = (key: string, value: string) => {
    const index = users.findIndex((v) => v.id == activeCompany)
    if (index == -1) return

    const newUsers = cloneDeep(users)
    newUsers[index].companyDetails[key] = value
    setUsers(newUsers)
  }

  const onBlockUserBalance = async (index: number, userId: number) => {
    const newBalances = cloneDeep(balances)
    newBalances[index].block = !newBalances[index].block

    setLoading(true)
    try {
      await blockUserBalance(userId)
      setBalances(newBalances)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const renderUsers = () => {
    return (
      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {_renderHeader('No', false, 'no')}
            {_renderHeader('Name', true, 'name')}
            {_renderHeader('Email', true, 'email')}
            {_renderHeader('Account Type', true, 'accountType')}
            {_renderHeader('Updated At', true, 'updatedAt')}
            {_isManager && _renderHeader('KYC', false, 'country')}
            {_renderHeader('Status', true, 'status')}
            {_renderHeader('Accredited', false, 'investReady')}
            {_renderHeader('W-8', true, 'taxCertPassed', 'whitespace-nowrap')}
            {_renderHeader('Certificate', true, 'certFormationPassed', 'whitespace-nowrap')}
            {_renderHeader('Actions', false, 'action')}
          </tr>
        </thead>
        <tbody className="text-[14px]">
          {users.map((item, index: number) => {
            return (
              <Fragment key={index}>
                <tr className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                  <td className="pl-2 py-3 w-10">{index + 1 + filters.pageNum * itemCountPerPage}</td>
                  <td className="px-2 py-2 ">
                    <Link
                      to={`/dashboard/manageUsers/${item.id}/${item.name.split(' ')[0]}`}
                      className="font-bold text-indigo-500 cursor-pointer hover:underline"
                    >
                      <Tooltip content={`Go to this investor's dashboard`}>
                        {item.name.length > 25 ? item.name.substring(0, 25) + '...' : item.name}
                      </Tooltip>
                    </Link>
                  </td>
                  <td className="px-2 py-2">
                    {item.email.length > 30 ? item.email.substring(0, 30) + '...' : item.email}
                  </td>
                  <td className="px-2 py-2">
                    <p className="flex items-center gap-2">
                      {AccountTypeText[item.accountType]}

                      {item.accountType == AccountType.Company && (
                        <BuildingOfficeIcon
                          className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200"
                          onClick={() => {
                            if (activeCompany === 0 || activeCompany !== item.id) setActiveCompany(item.id)
                            else setActiveCompany(0)
                          }}
                        />
                      )}
                    </p>
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatDateMDY(item.updatedAt)}</td>
                  {_isManager && (
                    <td className="py-3">
                      {item.status >= AccountStatus.KycPassed && (
                        <Tooltip content={item.country}>
                          <div
                            className="flex items-center gap-1.5 hover:underline cursor-pointer"
                            onClick={() => onSelectKyc(item)}
                          >
                            {item.country && <img src={flagLink(item.country)} className="w-6 h-4" />}
                            <p className="text-sm text-indigo-600">View</p>
                          </div>
                        </Tooltip>
                      )}
                    </td>
                  )}
                  <td className="px-2 min-w-[130px] w-44">
                    <div className="-mb-4">
                      <Select
                        id="account-status"
                        value={`${item.status}`}
                        options={AccountStatusText}
                        className="bg-white"
                        onChange={(value) => (_isManager ? onChangeUser(item.id, index, 'status', value) : {})}
                      />
                    </div>
                  </td>
                  <td className="px-2 py-3">
                    {item.hasAccredited && (
                      <div
                        className="flex items-center gap-2 hover:underline cursor-pointer"
                        onClick={() => setActiveInvestReady(item.id)}
                      >
                        <BuildingLibraryIcon className="w-4 h-4 text-indigo-600" />
                        <p className="text-xs text-indigo-600">View</p>
                      </div>
                    )}
                    {item.accreditedExpiresOn && (
                      <p className="text-desc mt-1">{formatDateMDY(item.accreditedExpiresOn)}</p>
                    )}
                  </td>

                  <td className="px-2 py-3">
                    {item.country && item.country != 'US' && (
                      <>
                        <div
                          className="flex items-center gap-2 hover:underline cursor-pointer"
                          onClick={() => setActiveTaxCert(item.id)}
                        >
                          <GlobeAltIcon className="w-4 h-4 text-indigo-600" />
                          <p className="text-xs text-indigo-600">View</p>
                        </div>
                        {item.taxCertPassed && <p className="text-desc mt-1">Approved</p>}
                      </>
                    )}
                  </td>
                  <td className="px-2 py-3">
                    {item.accountType &&
                      AccountType.Company === item.accountType &&
                      (item.accountDetails as CompanyDetails).isCert && (
                        <>
                          <div
                            className="flex items-center gap-2 hover:underline cursor-pointer"
                            onClick={() => setActiveCertFormat(item.id)}
                          >
                            <ClipboardDocumentListIcon className="w-4 h-4 text-indigo-600" />
                            <p className="text-xs text-indigo-600">View</p>
                          </div>
                          {item.certFormationPassed && <p className="text-desc mt-1">Approved</p>}
                        </>
                      )}
                  </td>
                  <td className="px-2 py-3 w-28">
                    <div className="flex items-center gap-1">
                      <Tooltip content={`Edit User Details`}>
                        <PencilSquareIcon
                          className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200"
                          onClick={() => onSelect(item)}
                        />
                      </Tooltip>
                      {_isManager && (
                        <>
                          <Tooltip content={`Reset Password`}>
                            <KeyIcon
                              className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200"
                              onClick={() => onResetPwd(index, item.name)}
                            />
                          </Tooltip>
                          {!filters.deleted && (
                            <Tooltip content={`Remove User`}>
                              <TrashIcon
                                className="w-6 h-6 cursor-pointer text-red-600 hover-shadow1 p-1 transition-all duration-200"
                                onClick={() => onDeleteUser(index, item.name)}
                              />
                            </Tooltip>
                          )}
                          {item.status == AccountStatus.Pending && (
                            <Tooltip content={`Send Email Verification`}>
                              <EnvelopeOpenIcon
                                className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200"
                                onClick={() => onSendConfirmEmail(item)}
                              />
                            </Tooltip>
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
                {activeCompany == item.id && (
                  <CompanyDetail user={item} onUpdate={onUpdateCompanyDetails} onClose={() => setActiveCompany(0)} />
                )}
                {activeInvestReady == item.id && (
                  <tr>
                    <td className="w-12"></td>
                    <td colSpan={9} className="px-2">
                      <InvestReadyDetails user={item} onClose={() => setActiveInvestReady(0)} />
                    </td>
                  </tr>
                )}
                {activeTaxCert == item.id && (
                  <tr>
                    <td className="w-12"></td>
                    <td colSpan={9} className="px-2">
                      <TaxCertDetails user={item} onClose={() => setActiveTaxCert(0)} />
                    </td>
                  </tr>
                )}
                {activeCertFormat == item.id && (
                  <tr>
                    <td className="w-12"></td>
                    <td colSpan={9} className="px-2">
                      <CertFormationDetails user={item} onClose={() => setActiveCertFormat(0)} />
                    </td>
                  </tr>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }

  const renderBalances = () => {
    return (
      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {_renderHeader('No', false, 'no')}
            {_renderHeader('Name', true, 'name')}
            {_renderHeader('Email', true, 'email')}
            {_renderHeader('Available', true, 'available')}
            {_renderHeader('Deposited', true, 'deposited')}
            {_renderHeader('Withdrawn', true, 'withdrawn')}
            {_renderHeader('Invested Amount', true, 'invested')}
            {_renderHeader('Approved Distribution', true, 'profits')}
            {_renderHeader('Pending Distribution', false, 'pendingDistribution')}
            {_renderHeader('Retraded Fee', false, 'retradedFee')}
            {_renderHeader('Actions', false, 'action')}
          </tr>
        </thead>
        <tbody className="text-[14px]">
          {balances.map((item, index: number) => {
            return (
              <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                <td className="pl-3 py-3 w-12">{index + 1}</td>
                <td className="px-2 py-2">
                  <Link
                    to={`/dashboard/manageUsers/${item.userId}/${item.name.split(' ')[0]}`}
                    className="font-bold text-indigo-500 cursor-pointer hover:underline"
                  >
                    <Tooltip content={`Go to this investor's dashboard`}>
                      {item.name.length > 25 ? item.name.substring(0, 25) + '...' : item.name}
                    </Tooltip>
                  </Link>
                </td>
                <td className="px-2 py-2">{item.email}</td>
                <td className="px-2 py-2 whitespace-nowrap">
                  {item.available >= 0 ? '' : '-'} ${normalizeNumber(Math.abs(item.available), 2)}
                </td>
                <td className="px-2 py-2 whitespace-nowrap">+ ${normalizeNumber(item.deposited)}</td>
                <td className="px-2 py-2 whitespace-nowrap">- ${normalizeNumber(item.withdrawn)}</td>
                <td className="px-2 py-2 whitespace-nowrap">- ${normalizeNumber(item.invested)}</td>
                <td className="px-2 py-2 whitespace-nowrap">+ ${normalizeNumber(item.profits, 2)}</td>
                <td className="px-2 py-2 whitespace-nowrap">${normalizeNumber(item.pendingDistribution, 2)}</td>
                <td className="px-2 py-2 whitespace-nowrap">- ${normalizeNumber(item.retradedFee)}</td>
                <td className="px-2">
                  <div className="flex items-center gap-1">
                    {_isManager && (
                      <>
                        <CurrencyDollarIcon
                          className="w-6 h-6 cursor-pointer text-green-400 hover-shadow1 p-1"
                          onClick={() => setMannualTrxUser(item)}
                        />

                        <Tooltip content="Block balance">
                          <Toggle
                            id="block-balance"
                            value={item.block}
                            onChange={() => onBlockUserBalance(index, item.userId)}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
        {balanceTotal != null && (
          <tfoot>
            <tr className="font-bold">
              <td colSpan={3} className="px-2 py-2 text-center">
                Total Amounts:
              </td>
              <td className="px-2 py-2">${normalizeNumber(balanceTotal['available'], 2)}</td>
              <td className="px-2 py-2">${normalizeNumber(balanceTotal['deposited'])}</td>
              <td className="px-2 py-2">${normalizeNumber(balanceTotal['withdrawn'])}</td>
              <td className="px-2 py-2">${normalizeNumber(balanceTotal['invested'])}</td>
              <td className="px-2 py-2">${normalizeNumber(balanceTotal['profits'], 2)}</td>
            </tr>
          </tfoot>
        )}
      </table>
    )
  }

  const renderTable = useMemo(() => {
    switch (showBalance) {
      case true:
        return renderBalances()
      case false:
        return renderUsers()
    }
  }, [users, balances, activeCompany, activeInvestReady, activeTaxCert, activeCertFormat, balanceTotal])

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <div className="md:flex items-center justify-between mb-0 sm:mb-10">
        <p className="text-4xl font-semibold  flex gap-4 items-center mb-10 sm:mb-0">
          {showBalance ? 'User Balances' : 'User Management'} ({total}) {isLoading && <Loading />}
        </p>
        {!showBalance && (
          <>
            <div className="flex justify-between items-center gap-4">
              <Toggle
                id="show-deleted"
                title="Trash"
                value={filters.deleted}
                onChange={(value) => onChangeFilter('deleted', value)}
              />
              {_isManager && <Button onClick={onAdd}>+ Add</Button>}
            </div>
          </>
        )}
      </div>

      {/* {showBalance && <BalanceStatistics />} */}

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          name="search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
          autoFocus={false}
        />
        {!showBalance && (
          <Select2
            id="accountType"
            title="Account Type"
            options={AccountTypeText}
            value={filters.accountType}
            className="capitalize"
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('accountType', value)}
          />
        )}
        {!showBalance && (
          <MultiSelect
            id="userStatus"
            title="User Status"
            options={Object.keys(AccountFilterStatusText)}
            value={filters.userStatus}
            className="capitalize"
            hasDefaultOption
            defaultOptionText=""
            onChange={(value) => onChangeFilter('userStatus', value)}
          />
        )}
      </div>

      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">{renderTable}</div>

      <div className="flex justify-between flex-wrap gap-2 items-center mt-3 mb-3">
        <Toggle
          id="balance"
          title="Balances"
          value={showBalance}
          onChange={() => {
            setFilters({
              query: defaultQuery,
              userStatus: defaultUserStatus,
              orderBy: 'updatedAt',
              orderDir: '-1',
              pageNum: 0,
              deleted: false,
            })
            setShowBalance(!showBalance)
          }}
        />

        <div>
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={filters.pageNum}
          />
        </div>
      </div>

      {selectedUser !== undefined && (
        <UserDetails
          readOnly={!_isManager}
          data={selectedUser as User}
          onClose={() => setSelectedUser(undefined)}
          onSubmit={onSubmitUser}
        />
      )}

      {kycSelectedUser && <UserKycDetails data={kycSelectedUser} onClose={() => setKycSelectedUser(undefined)} />}

      {currentMannualTrxUser && (
        <MannualTransaction
          user={currentMannualTrxUser as any as User}
          onClose={(isUpdate: boolean) => {
            setMannualTrxUser(null)
            isUpdate && filterBalanceData(filters)
          }}
        />
      )}

      {editPasswordId !== -1 && (
        <CustomPassword
          onClose={() => setEditPasswordId(-1)}
          onSubmit={onSubmitPassword}
          name={editPasswordName}
          loading={isLoading}
        />
      )}
    </div>
  )
}
