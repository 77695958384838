export enum CertFormatStatus {
  Rejected = -1,
  Pending = 0,
  Approved = 1,
}
export interface CertFormationHistory {
  status: CertFormatStatus
  reason: string
  createdBy: string
  createdAt: Date
}
export interface CertFormat {
  id: number
  fileURL: string
  fileName: string
  status: CertFormatStatus
  history: CertFormationHistory[]
  createdAt: Date
  updatedAt: Date
}

export const CertFormatStatusText: Record<CertFormatStatus, string> = {
  [CertFormatStatus.Rejected]: 'Rejected',
  [CertFormatStatus.Pending]: 'Pending',
  [CertFormatStatus.Approved]: 'Approved',
}

export const CertFormatStatusStyles: Record<CertFormatStatus, string> = {
  [CertFormatStatus.Rejected]: 'bg-yellow-200',
  [CertFormatStatus.Pending]: 'bg-gray-100',
  [CertFormatStatus.Approved]: 'bg-green-500 text-white',
}
