import { ClockIcon, EyeIcon } from '@heroicons/react/24/outline'
import { InputType } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { CertFormatHistoryModal } from 'pages/User/modal/CertFormatHistory'
import { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import type { RootState } from 'reducers'
import { getCertFormations, uploadCertFormation } from 'services/apis'
import { Button, PlainTable } from 'stories/components'
import { formatTime, InputConvert, InputValidate, RenderInput } from 'utils'

import { CertFormat, CertFormatStatus, CertFormatStatusStyles, CertFormatStatusText } from './CertFormatType'
const defaultInputs = (): Record<string, InputType> => {
  return {
    certificateFormation: {
      inputType: 'file',
      title: '',
      required: true,
      multiple: true,
    },
  }
}
export const CertFormationTab = () => {
  // const { profile } = useSelector((state: RootState) => state.auth)
  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<CertFormat[] | null>(null)
  // const dispatch = useDispatch()
  const [historyId, setHistoryId] = useState(0)
  useEffect(() => {
    getCertFormations().then(({ data }) => {
      setData(data)
    })
  }, [])

  const onView = (fileURL: string) => {
    window.open(fileURL, '_blank')
  }

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)

    newInputs[key].error = ''

    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])

      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value || ''
    })
    values['status'] = CertFormatStatus.Pending
    setInputs(newInputs)
    if (hasError) return false

    setLoading(true)
    uploadCertFormation(values, Array.from(newInputs['certificateFormation'].value))
      .then(() => {
        getCertFormations().then(({ data }) => {
          setData(data)
          setLoading(false)
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const renderData = () => {
    if (!data || !data.length) return null
    const tableData = data.map((item, index) => [
      index + 1,
      // TaxCertStatusText[item.status],
      <p className="text-blue-800">
        {item.fileName.length > 30 ? item.fileName.substring(0, 30) + '...' : item.fileName}
      </p>,
      <span className={`${CertFormatStatusStyles[item.status]} px-2 py-1 rounded`}>
        {CertFormatStatusText[item.status]}
      </span>,
      formatTime(item.createdAt),
      <div className="flex items-center gap-2 w-32">
        <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
          <Tooltip content={`View History`}>
            <ClockIcon className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200" />
          </Tooltip>
        </div>

        <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onView(item.fileURL)}>
          <Tooltip content={`View File`}>
            <EyeIcon className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200" />
          </Tooltip>
        </div>
      </div>,
    ])

    return (
      <div className="mb-6">
        <PlainTable
          tdClass="text-sm px-2 py-2"
          header={['No', 'File', 'Status', 'Created At', 'Action']}
          classNames={Array(10)
            .fill(0)
            .map(() => 'px-2 py-3')}
          data={tableData}
        />
      </div>
    )
  }

  const renderUploadSection = () => {
    return (
      <>
        <div className="my-4">
          <p className="text-xl font-medium">Upload Certificate of Formation</p>
        </div>
        <div className="flex justify-center">
          {RenderInput({
            input: inputs['certificateFormation'],
            Key: 'certificateFormation',
            onChange,
          })}
        </div>
      </>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <CertFormatHistoryModal createdBy history={item.history} onClose={() => setHistoryId(0)} />
  }

  return (
    <div>
      {renderData()} {renderUploadSection()}
      <div className="max-w-[16rem] mx-auto">
        <Button full className="font-medium py-3.5 px-6" onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </div>
      {renderHistoryModal()}
    </div>
  )
}
