import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    reason: {
      inputType: 'textarea',
      type: 'text',
      title: 'Message',
      value: '',
      span: 2,
      required: true,
    },
  }
}

export const CertFormatReasonModal = ({ forUser, onClose }: { forUser: boolean; onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    onClose(data.reason || '')
  }

  return (
    <Modal
      title="Are you sure to reject this certificate of formation?"
      titleOkay="Submit"
      loading={loading}
      onClose={() => onClose(null)}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        {forUser && (
          <p className="text-sm mb-2 border border-gray-600 bg-gray-100 rounded p-2">
            Please ensure that all values are entered correctly before submitting the information.
          </p>
        )}

        <div className="md:w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2 text-sm">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            input.title = forUser ? 'Message to Finresi' : 'Message to Investor'
            input.required = !forUser
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
