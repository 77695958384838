import { CertFormationHistory, CertFormatStatusStyles, CertFormatStatusText } from 'pages/Profile/CertFormatType'
import { Modal, PlainTable } from 'stories/components'
import { formatTimeLong } from 'utils'

export const CertFormatHistoryTable = ({
  history,
  createdBy,
}: {
  history: CertFormationHistory[]
  createdBy: boolean
}) => {
  const header = ['No', 'Status', 'Message', createdBy ? 'Created By' : '', 'Created At'].filter((v) => !!v)

  const tableData: any[] = history.map((item, index) =>
    [
      `${index + 1}`,
      <span className={`${CertFormatStatusStyles[item.status]} px-2 py-1 rounded`}>
        {CertFormatStatusText[item.status]}
      </span>,
      ,
      item.reason,
      createdBy ? item.createdBy : null,
      formatTimeLong(item.createdAt),
    ].filter((v) => v !== null),
  )

  return <PlainTable header={header} classNames={header.map(() => 'px-4 py-2')} tdClass="px-4 py-2" data={tableData} />
}

export const CertFormatHistoryModal = ({
  history,
  createdBy,
  onClose,
}: {
  history: CertFormationHistory[]
  createdBy: boolean
  onClose: Function
}) => {
  return (
    <Modal title={`Certificate History`} titleOkay="" onClose={() => onClose()} isOpen>
      <div className="text-gray-900 min-w-[48rem]">
        <CertFormatHistoryTable history={history} createdBy={createdBy} />
      </div>
    </Modal>
  )
}
