import {
  ArrowDownIcon,
  ArrowRightIcon,
  BuildingLibraryIcon,
  CheckIcon,
  GlobeAltIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { AccountStatus, AccountType } from 'config'
import { ProfileMenuTitles } from 'pages/Profile'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { RootState } from 'reducers'
import { Button, Modal } from 'stories/components'

export const CompleteVerificationProgress = ({
  from = '',
  investReady = true,
  onClose,
}: {
  from?: string
  investReady?: boolean
  onClose: Function
}) => {
  const { profile } = useSelector((state: RootState) => state.auth)
  const { accreditedExpiresOn, accountType } = profile
  const isNonUS = profile.country && profile.country != 'US'

  const navigate = useNavigate()

  const data = [
    {
      icon: UserIcon,
      title: 'Complete your profile',
      description: 'Providing Comprehensive Information for Better Investment Opportunities',
      status: AccountStatus.ProfileCompleted,
    },
    {
      icon: ShieldCheckIcon,
      title: 'Verify your identity',
      description:
        'KYC, or Know Your Customer, is a process implemented by businesses to verify the identity of customers.',
      status: AccountStatus.KycPassed,
    },
  ]

  if (investReady && !isNonUS)
    data.push({
      icon: BuildingLibraryIcon,
      title: 'Verify accredited investor status',
      description:
        'Accredited investor verification is the process of confirming whether an individual or entity meets the criteria set by the Securities and Exchange Commission (SEC) to be classified as an accredited investor.',
      status: AccountStatus.InvestreadyVerified,
    })

  if (isNonUS)
    data.push({
      icon: GlobeAltIcon,
      title: 'Complete Non U.S Investor Tax Forms',
      description: '',
      status: AccountStatus.TaxCert,
    })
  if (accountType === AccountType.Company) {
    data.push({
      icon: ShieldCheckIcon,
      title: 'Verify Certificate of Formation',
      description: '',
      status: AccountStatus.CertFormationVerified,
    })
  }

  const onContinue = () => {
    switch (profile.status) {
      case AccountStatus.Pending:
      case AccountStatus.EmailVerified:
        navigate(`/profile#${ProfileMenuTitles.PROFILE}`)
        return
      case AccountStatus.ProfileCompleted:
        navigate(`/profile#${ProfileMenuTitles.KYC}`)
        return
      case AccountStatus.KycPassed: {
        const investReadyPassed = !!accreditedExpiresOn && new Date(accreditedExpiresOn) > new Date()
        if (!investReadyPassed && !isNonUS) navigate(`/profile#${ProfileMenuTitles.INVEST_READY}`)
        else if (!profile.taxCertPassed && isNonUS) navigate(`/profile#${ProfileMenuTitles.TAX_CERT}`)
        else navigate(`/profile#${ProfileMenuTitles.CERT_FORMATION}`)
        return
      }
      case AccountStatus.AccreditedVerified:
        navigate(`/profile#${ProfileMenuTitles.CERT_FORMATION}`)
        return
      default:
        navigate(`/profile#${ProfileMenuTitles.CERT_FORMATION}`)
        return
    }
  }

  const showAccreditedMessage =
    from == '/deposit' && !!accreditedExpiresOn && new Date(accreditedExpiresOn) < new Date()

  return (
    <Modal title="" titleOkay="" titleCancel="" onClose={() => onClose()} isOpen>
      <div className={`${accountType === AccountType.Company ? 'lg:w-[40rem]' : 'lg:w-[33rem]'}`}>
        <p className="text-lg font-semibold text-center">
          In order to invest with Finresi, you must complete the outstanding account requirements.
        </p>

        <div className="flex-row sm:grid sm:grid-cols-11 mt-4 mb-2">
          {data.map(({ title, status }, index) => {
            let isPassed = profile.status >= status
            if (status == AccountStatus.InvestreadyVerified)
              isPassed = !!accreditedExpiresOn && new Date(accreditedExpiresOn) > new Date()

            if (status == AccountStatus.TaxCert) isPassed = profile.taxCertPassed

            return (
              <>
                <div
                  className={`relative py-4 px-2 ${accountType === AccountType.Company ? `col-span-2` : `col-span-3`}`}
                  key={index}
                >
                  <div className="flex gap-2 flex-col items-center justify-center">
                    <div>
                      <div
                        className={`border border-gray-200 w-10 h-10 rounded-full ${
                          isPassed ? 'bg-indigo-500 text-white' : ''
                        }`}
                      >
                        <CheckIcon className={`${isPassed ? 'text-white' : 'text-gray-300'} w-10 h-10 p-2`} />
                      </div>
                    </div>
                    <p className="text-base text-gray-800 text-center bg-gray-100 rounded px-2 py-0.5">
                      Step {index + 1}
                    </p>

                    <p className="text-xs font-medium text-gray-800 text-center">{title}</p>
                  </div>
                  {/* <p className="text-desc ml-10">{description}</p> */}
                </div>
                {index != 2 && accountType !== AccountType.Company && (
                  <>
                    <div>
                      <ArrowRightIcon className="hidden sm:flex w-6 h-6 text-gray-300 mx-auto mt-6" />
                      <ArrowDownIcon className="flex sm:hidden w-6 h-6 sm:w-0 sm:h-0 text-gray-300 mx-auto" />
                    </div>
                  </>
                )}
                {index != 3 && accountType === AccountType.Company && (
                  <>
                    <div>
                      <ArrowRightIcon className="hidden sm:flex w-6 h-6 text-gray-300 mx-auto mt-6" />
                      <ArrowDownIcon className="flex sm:hidden w-6 h-6 sm:w-0 sm:h-0 text-gray-300 mx-auto" />
                    </div>
                  </>
                )}
              </>
            )
          })}
        </div>

        {showAccreditedMessage && (
          <p className="border border-gray-300 text-sm p-2 rounded-lg bg-gray-100 mt-4">
            - Your accredited investor verification has <b>expired</b> and is required to be updated to deposit
            additional funds.
          </p>
        )}

        <div className="w-full pt-4">
          <div className="flex gap-2 w-72 mb-1 mx-auto">
            <Button color="indigo" full className="font-medium py-3.5 px-6" onClick={onContinue}>
              Continue with account set up
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
