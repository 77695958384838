// import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
// import { StarIcon } from '@heroicons/react/24/solid'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { InputType, isManager, itemCountPerPage } from 'config'
import { cloneDeep } from 'lodash'
import { SignInModal } from 'pages/Auth/modal'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import type { RootState } from 'reducers'
import { findLoans, getMarketplaceFilterOptions } from 'services/apis'
import {
  addTitleToLoans,
  getPrice3decimal,
  InputConvert,
  normalizeNumber,
  RenderInput,
  toS3Link,
  useTitle,
} from 'utils'

import { defaultFilterInputs } from './constants'
import { remainingLoanTerm } from './InvestCard' // FeaturedInvestCard
import { PastLoanImage } from './PastLoanImage'
import { Loan, LoanStatus, MultiFamilyPropertyTypes } from './types'

export * from './item'
export * from './modal'
export * from './types'

const defaultFilters: Record<string, any> = {
  closed: false,
  orderBy: 'loanAmount',
  orderDir: -1,
  pageNum: 0,
}

export const MarketplacePage = ({ requireAuth = true }: { requireAuth?: boolean }) => {
  useTitle('Marketplace')

  const [isFilterLoading, setFilterLoading] = useState(false)
  const [filteredLoans, setFilteredLoans] = useState<Loan[]>([])
  const [pastLoans, setPastLoans] = useState<Loan[]>([])
  const [filterInputs, setFilterInputs] = useState<Record<string, InputType>>(defaultFilterInputs())
  const [filters, setFilters] = useState<Record<string, any>>(defaultFilters)
  const [total, setTotal] = useState(0)
  const [isFilterOptionsLoaded, setFilterOptionLoaded] = useState(false)
  const auth = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = auth
  const _isManager = auth && isManager(auth.profile.accountType)
  const [isSignInModal, showSignInModal] = useState(false)
  const [overEdit, setOverEdit] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    initFilterOptions()

    if (requireAuth && (!auth || !isAuthenticated)) showSignInModal(true)
  }, [])

  const initFilterOptions = async () => {
    const { states, propertyTypes } = await getMarketplaceFilterOptions()
    const newFilterInputs = cloneDeep(defaultFilterInputs())
    ;(newFilterInputs.state as any).options = states
    ;(newFilterInputs.propertyType as any).options = propertyTypes
    // ;(newFilterInputs.termLeft as any).max = maxTermLeft
    // newFilterInputs.termLeft.value = [0, maxTermLeft]
    // ;(newFilterInputs.ytm as any).max = maxYtm
    // newFilterInputs.ytm.value = [0, maxYtm]

    setFilterInputs(newFilterInputs)
    setFilterOptionLoaded(true)

    filterLoanData(defaultFilters, 0)
  }

  const filterLoanData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (filteredLoans.length == 0) _pageNum = 0
    setFilterLoading(true)

    filters.pageNum = _pageNum
    setFilters(filters)

    return findLoans(
      {
        ...filters,
        ltv: filters.ltv ? filters.ltv.split('-') : undefined,
        termLeft: filters.termLeft ? filters.termLeft.split('-') : undefined,
        status: [LoanStatus.ACTIVE, LoanStatus.PAST],
        skip: _pageNum * itemCountPerPage,
        count: itemCountPerPage,
      },
      isAuthenticated,
    )
      .then(({ data: loans, total, pastData }) => {
        setTotal(total)

        const data = addTitleToLoans(loans)
        if (_pageNum == 0) setFilteredLoans(data)
        else {
          const newLoans = cloneDeep(filteredLoans)
          newLoans.push(...data)
          setFilteredLoans(newLoans)
        }

        const newPastData = addTitleToLoans(pastData)
        setPastLoans(newPastData)
      })
      .finally(() => setFilterLoading(false))
  }

  const onResetFilters = () => {
    setFilters(defaultFilters)
    initFilterOptions()
  }

  // const onSort = (orderBy: string) => {
  //   if (!orderBy) return

  //   const newFilters = Object.assign({}, filters)
  //   if (newFilters.orderBy == orderBy) newFilters.orderDir = 0 - Number(newFilters.orderDir)
  //   newFilters.orderBy = orderBy
  //   // setFilters(newFilters)
  //   filterLoanData(newFilters, 0)
  // }

  const onChangeFilter = (key: 'closed' | 'orderBy' | 'orderDir' | 'pageNum', value: any, refetch = true) => {
    if (isFilterLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    filterLoanData(newFilters, refetch ? 0 : -1)
  }

  const onChangeFilterInput = (key: string, value: string, needRefresh = false) => {
    let newState = cloneDeep(filterInputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setFilterInputs(newState)

    if (newState[key].inputType == 'select0' || needRefresh) onBlurFilterInput(key, value)
  }

  const onBlurFilterInput = (key: string, value: any = null) => {
    if (isFilterLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value === null ? filterInputs[key].value : value
    setFilters(newFilters)
    filterLoanData(newFilters, 0)
  }

  const seeMorePastLoans = () => {
    if (isFilterLoading) return
    const newState = cloneDeep(defaultFilterInputs())
    newState.closed.value = true
    setFilterInputs(newState)

    const newFilters = Object.assign({}, defaultFilters)
    newFilters.closed = true
    setFilters(newFilters)
    filterLoanData(newFilters, 0)
  }

  return (
    <div className="w-full bg-gray-100/50">
      <div className="fullContent py-4">
        <div className="mb-4">
          <p className="text-lg font-bold">
            {filters.closed ? 'Past' : 'Investment'} Opportunities ({total})
          </p>
          <p className="text-desc">
            Browse, search or filter the following opportunities, then click on any one to learn and to invest.
          </p>
        </div>

        <div className="mb-4 text-sm">
          <div className="grid lg:grid-cols-8 sm:grid-cols-4 grid-cols-2 bg-gray-200/40 p-4 pb-0 rounded-md justify-between flex-wrap gap-2">
            {isFilterOptionsLoaded &&
              Object.keys(filterInputs).map((key, index) => {
                const input = filterInputs[key] as any
                const isSelect = input.inputType == 'select0'
                if (key == 'query') {
                  ;(input.hasIcon = true), (input.icon = <MagnifyingGlassIcon className="w-4 h-4" />)
                }

                return (
                  <div className={`input ${key == 'query' ? 'col-span-2' : ''}`} key={index}>
                    <RenderInput
                      input={input}
                      Key={key}
                      onChange={onChangeFilterInput}
                      onBlur={isSelect ? null : onBlurFilterInput}
                    />
                  </div>
                )
              })}
          </div>

          {!!Object.keys(filterInputs).filter((key) => !!filters[key]).length && (
            <div className="text-sm flex gap-2 items-center flex-wrap">
              <p className="underline cursor-pointer hover:text-indigo-500" onClick={onResetFilters}>
                Clear
              </p>
              {Object.keys(filterInputs).map((key) => {
                if (!filters[key]) return null
                const input = filterInputs[key] as any
                const v = filters[key]
                return (
                  <p
                    className="flex items-center gap-1 px-2 py-1 border rounded-md cursor-pointer hover:bg-gray-200 bg-white"
                    onClick={() => onChangeFilterInput(key, '', true)}
                  >
                    {key == 'query' ? 'Query: ' : ''}
                    {key == 'closed' ? `Investment: ${v ? 'Closed' : 'Open'}` : ''}
                    {input.options && input.options[v] ? input.options[v] : v}
                    <XMarkIcon className="w-3 h-3 text-gray-500" />
                  </p>
                )
              })}
            </div>
          )}
        </div>

        <div className="flex-[4]">
          {!filters.closed && (
            <p className="text-lg font-bold mb-4 px-2 py-0.5 border-2 border-light-green rounded text-light-green w-fit">
              Live Opportunities
            </p>
          )}
          <InfiniteScroll
            dataLength={filteredLoans.length}
            next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
            hasMore={filteredLoans.length < total}
            loader={<div className="relative h-10">{isFilterLoading && <LayoutLoading show />}</div>}
          >
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              {filteredLoans.map((item, index) => (
                <div
                  className="rounded-lg overflow-hidden relative hover:cursor-pointer"
                  key={index}
                  onClick={() => {
                    if (!overEdit) navigate(isAuthenticated ? `/marketplace/${item.id}` : '/auth/signUp')
                  }}
                >
                  {item.closed ? (
                    <div className="relative group">
                      <PastLoanImage
                        src={toS3Link(item.images)}
                        className="w-full block aspect-video object-cover filter grayscale blur-2 group-hover:grayscale-0 group-hover:blur-0"
                      />
                    </div>
                  ) : (
                    <Image src={toS3Link(item.images)} className="w-full block aspect-video object-cover" />
                  )}
                  <div className="bg-white p-4 pb-2 border-l border-r border-gray-300">
                    <div className="flex gap-4 items-center mb-1">
                      <div className="flex-1">
                        <p className="text-xs">{item.progress}% Funded</p>
                        <div className="w-full bg-gray-200 rounded-full">
                          <div className="bg-light-green h-1 rounded-full" style={{ width: `${item.progress}%` }} />
                        </div>
                      </div>
                      {!item.closed ? (
                        <span className="bg-light-green text-xs text-white font-semibold px-1.5 py-1 rounded-md capitalize">
                          $ {getPrice3decimal(item.loanAmount)}
                        </span>
                      ) : (
                        <span className=" bg-gray-200 text-xs text-black font-semibold px-1.5 py-1 rounded-md capitalize">
                          $ {getPrice3decimal(item.loanAmount)}
                        </span>
                      )}
                    </div>

                    <p className="font-bold mb-1 text-base">
                      {item.title} #{item.id}
                    </p>
                    <p className="text-xs text-gray-400 h-8">
                      {MultiFamilyPropertyTypes.includes(item.propertyType)
                        ? 'Multifamily Property with ' + item.units + ' units'
                        : item.propertyType +
                          ' with ' +
                          item.bedrooms +
                          ' bedrooms and ' +
                          item.bathrooms +
                          ' full bath'}
                    </p>
                  </div>

                  <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0 pb-2">
                    <div className="flex divide-x">
                      {[
                        [`${item.ytm}%`, 'APY'],
                        [`${normalizeNumber(item.ltv, 2)}%`, 'LTV'],
                        [remainingLoanTerm(item.maturityDate), 'TERM'],
                      ].map((v, idx) => (
                        <div key={`${index}-${idx}`} className="flex-1 text-center py-2">
                          <p className="font-bold text-xs">{v[0]}</p>
                          <p className="text-2xs text-gray-400">{v[1]}</p>
                        </div>
                      ))}
                    </div>
                    <div className="px-4 py-2">
                      <div className={`flex gap-4 items-center ${_isManager ? 'justify-between' : 'justify-center'}`}>
                        <Link
                          to={isAuthenticated ? `/marketplace/${item.id}` : '/auth/signUp'}
                          className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                        >
                          {/* {item.closed || item.funds == item.loanAmount ? 'View Details' : 'Invest Now'} */}
                          View Details
                        </Link>

                        {_isManager && (
                          <Link
                            to={`/pipeline/${item.id}`}
                            className="underline hover:text-indigo-500 focus:ring-4 focus:ring-green-300 font-semibold text-sm flex-1 text-center"
                            onMouseEnter={() => setOverEdit(true)}
                            onMouseLeave={() => setOverEdit(false)}
                          >
                            Edit
                          </Link>
                        )}
                      </div>

                      {_isManager && (
                        <div className={`flex gap-4 items-center justify-between mt-3`}>
                          <Link
                            to={`/manageSignStatistics?query=__${item.id}`}
                            className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                            onMouseEnter={() => setOverEdit(true)}
                            onMouseLeave={() => setOverEdit(false)}
                          >
                            Investments
                          </Link>

                          <Link
                            to={`/manageProfits?query=___${item.id}&status=all`}
                            className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                            onMouseEnter={() => setOverEdit(true)}
                            onMouseLeave={() => setOverEdit(false)}
                          >
                            Distributions
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>

      {!filters.closed && (
        <div className="fullContent py-4 border-t">
          <div className="mb-4 flex justify-between">
            <p className="text-lg font-bold">Past Opportunities</p>
            <p
              className="hover:text-indigo-500 cursor-pointer underline transition-all duration-200 font-semibold"
              onClick={seeMorePastLoans}
            >
              See more
            </p>
          </div>

          <div className="flex-[4]">
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
              {pastLoans.map((item, index) => (
                <div
                  className="rounded-lg overflow-hidden relative hover:cursor-pointer"
                  key={index}
                  onClick={() => {
                    if (!overEdit) navigate(isAuthenticated ? `/marketplace/${item.id}` : '/auth/signUp')
                  }}
                >
                  <div className="relative group">
                    <PastLoanImage
                      src={toS3Link(item.images)}
                      className="w-full block aspect-video object-cover filter grayscale blur-2 group-hover:grayscale-0 group-hover:blur-0"
                    />
                  </div>
                  <div className="bg-white p-4 pb-2 border-l border-r border-gray-300">
                    <div className="flex gap-4 items-center mb-1">
                      <div className="flex-1">
                        <p className="text-xs">{item.progress}% Funded</p>
                        <div className="w-full bg-gray-200 rounded-full">
                          <div className="bg-light-green h-1 rounded-full" style={{ width: `${item.progress}%` }} />
                        </div>
                      </div>
                      {!item.closed ? (
                        <span className="bg-light-green text-xs text-white font-semibold px-1.5 py-1 rounded-md capitalize">
                          $ {getPrice3decimal(item.loanAmount)}
                        </span>
                      ) : (
                        <span className=" bg-gray-200 text-xs text-black font-semibold px-1.5 py-1 rounded-md capitalize">
                          $ {getPrice3decimal(item.loanAmount)}
                        </span>
                      )}
                    </div>

                    <p className="font-bold mb-1 text-base">
                      {item.title} #{item.id}
                    </p>
                    <p className="text-xs text-gray-400 h-8">
                      {MultiFamilyPropertyTypes.includes(item.propertyType)
                        ? 'Multifamily Property with ' + item.units + ' units'
                        : item.propertyType +
                          ' with ' +
                          item.bedrooms +
                          ' bedrooms and ' +
                          item.bathrooms +
                          ' full bath'}
                    </p>
                  </div>

                  <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0 pb-2">
                    <div className="flex divide-x">
                      {[
                        [`${item.ytm}%`, 'APY'],
                        [`${normalizeNumber(item.ltv, 2)}%`, 'LTV'],
                        [remainingLoanTerm(item.maturityDate), 'TERM'],
                      ].map((v, idx) => (
                        <div key={`${index}-${idx}`} className="flex-1 text-center py-2">
                          <p className="font-bold text-xs">{v[0]}</p>
                          <p className="text-2xs text-gray-400">{v[1]}</p>
                        </div>
                      ))}
                    </div>
                    <div className="px-4 py-2">
                      <div className={`flex gap-4 items-center ${_isManager ? 'justify-between' : 'justify-center'}`}>
                        <Link
                          to={isAuthenticated ? `/marketplace/${item.id}` : '/auth/signUp'}
                          className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                        >
                          {/* {item.closed || item.funds == item.loanAmount ? 'View Details' : 'Invest Now'} */}
                          View Details
                        </Link>

                        {_isManager && (
                          <Link
                            to={`/pipeline/${item.id}`}
                            className="underline hover:text-indigo-500 focus:ring-4 focus:ring-green-300 font-semibold text-sm flex-1 text-center"
                            onMouseEnter={() => setOverEdit(true)}
                            onMouseLeave={() => setOverEdit(false)}
                          >
                            Edit
                          </Link>
                        )}
                      </div>

                      {_isManager && (
                        <div className={`flex gap-4 items-center justify-between mt-3`}>
                          <Link
                            to={`/manageSignStatistics?query=__${item.id}`}
                            className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                            onMouseEnter={() => setOverEdit(true)}
                            onMouseLeave={() => setOverEdit(false)}
                          >
                            Investments
                          </Link>

                          <Link
                            to={`/manageProfits?query=___${item.id}&status=all`}
                            className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green"
                            onMouseEnter={() => setOverEdit(true)}
                            onMouseLeave={() => setOverEdit(false)}
                          >
                            Distributions
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {isSignInModal && <SignInModal onClose={() => showSignInModal(false)} />}
    </div>
  )
}
